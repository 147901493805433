import { useLocation, useParams } from "@remix-run/react";
import { ProgressBarSecondary } from "../progress_bar/progress_bar_secondary";
import styles from "./nav_bar_secondary.module.css";
import { useMemo } from "react";
import { quiz_questions_2 } from "~/data/quiz_questions";
import { isScreenDisabled } from "~/data/disabled_screens";
import { getQuizIdentifierFromPath } from "~/data";

export function NavBarSecondary() {
	const { pathname } = useLocation();
	const { questionId } = useParams();
	const { currentQuiz } = getQuizIdentifierFromPath();
	const currentQuestionId = useMemo(
		() => parseInt(questionId ?? "", 10),
		[questionId]
	);

	const currentQuestionIndex = useMemo(
		() =>
			quiz_questions_2.findIndex((item) => item?.id === currentQuestionId) +
			1,
		[currentQuestionId]
	);

	const headerText = useMemo(() => {
		if (currentQuestionIndex <= 12) return "Your history";
		if (currentQuestionIndex <= 18) return "Tendencies";
		if (currentQuestionIndex <= 23) return "Struggles signs";
		if (currentQuestionIndex === 24) return "Final Question";

		return null;
	}, [currentQuestionIndex]);

	if (isScreenDisabled(currentQuiz, pathname)) {
		return null;
	}

	return (
		<nav className={styles.nav_wrap}>
			<div className={styles.bg}>
				<div className={styles.container}>
					<h2 className="inter500 sp12 orange txt_center">{headerText}</h2>
					<ProgressBarSecondary />
				</div>
			</div>
			<div className={styles.bottom_shadow} />
		</nav>
	);
}
