import { QuizIdentifier } from "./quiz_identifiers";

const commonScreens: string[] = [
	"/canceled",
	"/discount",
	"/manage",
	"/reason",
	"/success",
	"/successchange",
];

export const disable_screens: Record<QuizIdentifier, string[]> = {
	[QuizIdentifier.Quiz_1]: [...commonScreens],
	[QuizIdentifier.Quiz_2]: [
		...commonScreens,
		"/email",
		"/generate",
		"/mood-journal-effect",
		"/personalized-plan",
		"/risk-assessment",
	],
};

export const isScreenDisabled = (
	quizIdentifier: QuizIdentifier,
	pathname: string
): boolean => {
	return disable_screens[quizIdentifier]?.includes(pathname) ?? false;
};
