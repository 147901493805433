import { AnimatePresence, motion } from "framer-motion";
import styles from "./modal.module.css";
import EmailModal from "./email_modal";
import Experience from "./experience";
import WellbeingModal from "./wellbeing_modal";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";
import { CustomModalRef, ModalController, TModalTypes } from "./entity";

function Modal() {
	const [type, setType] = useState<TModalTypes>();
	const [visible, setModalVisible] = useState(false);
	const callback = useRef<() => void>();
	const modalRef = useRef<CustomModalRef>();

	const components: Record<TModalTypes, () => JSX.Element> = {
		email: EmailModal,
		experience: Experience,
		wellbeing: WellbeingModal,
	};

	const getComponent = useMemo(() => {
		if (!type) return null;

		const Component = components[type] ?? null;
		if (Component == null) return null;

		return <Component />;
	}, [type]);

	useImperativeHandle(
		modalRef,
		() => ({
			show: (type: TModalTypes, cb?: () => void) => {
				setModalVisible(true);
				setType(type);
				callback.current = cb;
			},
			hide: () => {
				setModalVisible(false);
				if (callback.current) callback.current();
			},
		}),
		[]
	);

	useEffect(() => {
		ModalController.setModalRef(modalRef);
	}, []);

	return (
		<AnimatePresence>
			{visible && (
				<>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.75 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.15 }}
						className={`${styles.overlay} absolute`}
					/>
					<div className={`${styles.content_wrap} absolute`}>
						<motion.div
							initial={{ opacity: 0, scale: 0.95 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, scale: 0.95 }}
							transition={{ duration: 0.15 }}
							className={styles.modal_content}
							role="dialog"
							aria-modal="true"
							aria-labelledby="modal-title"
						>
							{getComponent}
						</motion.div>
					</div>
				</>
			)}
		</AnimatePresence>
	);
}

export default forwardRef(Modal);
