import { useCallback, useState } from "react";
import FlexBox from "../questions/parts/flex/flex_box";
import { useNavigate } from "@remix-run/react";
import { ModalController } from "./entity";
import { SmallBtn } from "../questions/parts/button/small_button";
import tracking from "~/data/tracking";
import storage, { StorageKeys } from "~/data/local_storage";

export default function Experience() {
	const navigate = useNavigate();
	const [selectedId, setSelectedId] = useState<number>();

	const no = useCallback(() => {
		setSelectedId(0);
		setTimeout(() => {
			sendForm("0");
		}, 300);
	}, []);

	const yes = useCallback(() => {
		setSelectedId(1);
		setTimeout(() => {
			sendForm("1");
		}, 300);
	}, []);

	const sendForm = (val: string) => {
		storage.set(StorageKeys.experience, val?.toString() ?? "");
		tracking.track("pop_up_tried_mood_journal", {
			pop_up: val == "1" ? true : false,
		});

		ModalController.hideModal();
		return navigate("/generate", {
			replace: true,
		});
	};

	const css = {
		background: "var(--black-color)",
		color: "var(--white-color)",
		flex: 1,
		height: "64px",
	};

	return (
		<>
			<h3 className="inter700 sp26 txt_center white">
				Have you ever tried mood journaling?
			</h3>
			<FlexBox
				style={{
					marginTop: 28,
					// alignItems: "stretch",
					gap: 16,
					width: "100%",
				}}
			>
				<SmallBtn
					text="No"
					style={{
						...css,
						transform: `scale(${selectedId == 0 ? "1.1" : "1"})`,
					}}
					onClick={no}
				/>
				<SmallBtn
					text="Yes"
					style={{
						...css,
						transform: `scale(${selectedId == 1 ? "1.1" : "1"})`,
					}}
					onClick={yes}
				/>
			</FlexBox>
		</>
	);
}
