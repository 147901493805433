import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";

type TCountDimerProps = {
	marginTop?: number;
	borderTopLeftRadius?: number;
	borderTopRightRadius?: number;
	backgroundColor?: string;
	text?: string;
	textColor?: string;
	width?: string;
	top?: number;
	minutes?: string;
};

export default function CountDownTimer({
	marginTop,
	minutes = "10",
	borderTopLeftRadius,
	borderTopRightRadius,
	backgroundColor,
	text,
	textColor,
	width,
	top,
}: TCountDimerProps) {
	const [timeString, setTimeString] = useState("");
	const [hide, setHide] = useState<boolean>(false);
	const requestRef = useRef<number>();
	const startTime = useRef<number>();

	useEffect(() => {
		const end = new Date();
		end.setMinutes(end.getMinutes() + parseInt(minutes));
		startTime.current = Date.now();

		const updateTimer = () => {
			const diff = end.getTime() - Date.now();
			const min = getMinutes(diff);
			const sec = getSeconds(diff);

			if (parseInt(min) < 0 || parseInt(sec) < 0) {
				setTimeString(`00:00`);
				setHide(true);
				return;
			}

			setTimeString(`${min.padStart(2, "0")}:${sec.padStart(2, "0")}`);
			requestRef.current = requestAnimationFrame(updateTimer);
		};

		requestRef.current = requestAnimationFrame(updateTimer);

		return () => {
			if (requestRef.current) cancelAnimationFrame(requestRef.current);
		};
	}, []);

	const getMinutes = (val: number) => {
		return Math.floor(val / 1000 / 60).toString();
	};
	const getSeconds = (val: number) => {
		return (Math.floor(val / 1000) % 60).toString();
	};

	if (hide) return null;

	return (
		<div
			className={`${styles.wrap_banner} `}
			style={{
				marginTop: marginTop,
				borderTopLeftRadius: borderTopLeftRadius,
				borderTopRightRadius: borderTopRightRadius,
				backgroundColor: backgroundColor,
				color: textColor,
				width: width,
				top: top,
			}}
		>
			<span className="inter400 sp14 ">
				{text ? text : "⏳ Discount expires in:"}
			</span>
			<div className={styles.time_wrap}>
				<span className="inter700 sp16 ">{timeString}</span>
			</div>
		</div>
	);
}
