import { useEffect, useState, useMemo } from "react";
import styles from "./progress_bar_secondary.module.css";
import { useParams } from "@remix-run/react";
import { quiz_questions_2 } from "~/data/quiz_questions";

export function ProgressBarSecondary() {
	const { questionId } = useParams();
	const [progress, setProgress] = useState<number>(0);
	const totalQuestions = quiz_questions_2.length;
	const stepsCount = 4;

	const currentQuestionId = useMemo(
		() => parseInt(questionId ?? "", 10),
		[questionId]
	);

	const currentQuestionIndex = useMemo(
		() =>
			quiz_questions_2.findIndex((item) => item?.id === currentQuestionId) +
			1,
		[currentQuestionId]
	);

	const thresholds = useMemo(() => {
		return Array.from(
			{ length: stepsCount },
			(_, i) => (i / (stepsCount - 1)) * 100
		);
	}, [stepsCount]);

	useEffect(() => {
		if (currentQuestionIndex > 0) {
			const calculatedProgress =
				(currentQuestionIndex / totalQuestions) * 100;
			if (calculatedProgress !== progress) {
				setProgress(calculatedProgress);
			}
		}
	}, [currentQuestionIndex, totalQuestions, progress]);

	return (
		<div className={styles.progress_container}>
			<div className={styles.progress_bar_wrapper}>
				<div className={styles.progress_bar_background}>
					<div
						className={styles.progress_bar_fill}
						style={{ width: `${progress}%` }}
					/>
				</div>
				<div className={styles.step_circles}>
					{thresholds.map((threshold) => (
						<div
							key={`step-${threshold}`}
							className={`${styles.step_circle} ${
								progress >= threshold ? styles.active : ""
							}`}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
