import { CSSProperties, PropsWithChildren } from "react";

type TFlexBoxProps = {
	style?: CSSProperties;
};

export default function FlexBox({
	style,
	children,
	...props
}: PropsWithChildren<TFlexBoxProps>) {
	const defaultStyle: React.CSSProperties = {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		...style,
	};

	return <div style={{ ...defaultStyle, ...props }}>{children}</div>;
}
