import { cssBundleHref } from "@remix-run/css-bundle";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/bottom_sheet.css";
import "./css/main.css";
import "./css/reset.css";
import "./css/variables.css";

import { LinksFunction, MetaFunction } from "@remix-run/node";
import {
	isRouteErrorResponse,
	Links,
	Meta,
	Scripts,
	ScrollRestoration,
	useLocation,
	useOutlet,
	useRouteError,
} from "@remix-run/react";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import NotFoundSection from "./components/404";
import Modal from "./components/modal/modal";
import { NavBar } from "./components/questions/parts/nav_bar/nav_bar";
import { NavBarSecondary } from "./components/questions/parts/nav_bar/nav_bar_secondary";
import { getQuizIdentifierFromPath } from "./data";
import cookieBanner from "./data/cookie_banner";
import fb from "./data/facebook";
import { QuizIdentifier } from "./data/quiz_identifiers";
import tracking from "./data/tracking";

export const meta: MetaFunction = () => {
	return [
		{
			title: "FABU - track your mood",
		},
		{
			description:
				"FABU is a unique mood journal for your mental wellbeing! With our app you can understand your emotions, track your moods and express yourself through fashion and creativity.",
		},
	];
};

export const links: LinksFunction = () => {
	return [
		...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
		{
			rel: "preconnect",
			href: "https://fonts.googleapis.com",
		},
		{
			rel: "preconnect",
			href: "https://fonts.gstatic.com",
		},
		{
			rel: "stylesheet",
			href: "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
		},
	];
};

export function ErrorBoundary() {
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		if (error.status === 404) {
			return <NotFoundSection />;
		}

		throw new Error(`${error.status} ${error.statusText}`);
	}

	captureRemixErrorBoundaryError(error);

	throw new Error(error instanceof Error ? error.message : "Unknown Error");
}

export function Layout({ children }: { children: React.ReactNode }) {
	return (
		<html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"
				/>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>
				<link rel="manifest" href="/site.webmanifest" />
				<link
					rel="mask-icon"
					href="/safari-pinned-tab.svg"
					color="#000000"
				/>
				<meta name="msapplication-TileColor" content="#000000" />
				<meta name="theme-color" content="#000000"></meta>
				<Meta />
				<Links />
			</head>
			<body>
				{children}
				<ScrollRestoration />
				<Scripts />
				<div id="sheet-content"></div>
				<ToastContainer />
				<script
					id="cookieyes"
					type="text/javascript"
					src="https://cdn-cookieyes.com/client_data/5f94f2fac18d64e11a0ef66e/script.js"
				/>
			</body>
		</html>
	);
}

function App() {
	const outlet = useOutlet();
	const location = useLocation();
	const { currentQuiz } = getQuizIdentifierFromPath();
	const [lastLocation, setLastLocation] = useState(
		window?.location.pathname ?? "/"
	);

	const renderNavBar = useMemo(() => {
		switch (currentQuiz) {
			case QuizIdentifier.Quiz_1:
				return <NavBar />;
			case QuizIdentifier.Quiz_2:
				return <NavBarSecondary />;
			default:
				return null;
		}
	}, [currentQuiz]);

	useEffect(() => {
		if (
			typeof window !== "undefined" &&
			lastLocation !== window.location.pathname
		) {
			setLastLocation(window.location.pathname);
			fb.trackPageView();
		}
	}, [location]);

	useEffect(() => {
		fb.init();
		cookieBanner.init();
		tracking.trackAttribution();
	}, []);

	return (
		<>
			{renderNavBar}
			<main id="app_container">
				<AnimatePresence mode="wait" initial={false}>
					{outlet}
				</AnimatePresence>
				<Modal />
			</main>
		</>
	);
}

export default withSentry(App);
