import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "@remix-run/react";
import styles from "./nav_bar.module.css";
import { useCallback, useMemo } from "react";
import ProgressBar from "../progress_bar/progress_bar";
import CountDownTimer from "~/components/premium/parts/count_down_timer";
import { isScreenDisabled } from "~/data/disabled_screens";
import { getQuizIdentifierFromPath } from "~/data";

export function NavBar() {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const { currentQuiz } = getQuizIdentifierFromPath();

	const isMain = location.pathname == "/";
	const isPremium = location.pathname == "/premium";
	const width = isMain ? 126 : 84;
	const height = isMain ? 42 : 28;

	const isOffer = searchParams.get("offer") == "1";

	const onClick = useCallback((e: any) => {
		e.preventDefault();
		navigate(-1);
	}, []);

	const Timer = useMemo(() => {
		if (location.pathname != "/premium") {
			return null;
		}

		return !isOffer ? (
			<CountDownTimer key="usual" minutes={import.meta.env.VITE_TIMER} />
		) : (
			<CountDownTimer
				key="special"
				minutes={"5"}
				backgroundColor="#d01e13"
				text="🔥 One time limited offer:"
				textColor="#fff"
			/>
		);
	}, [location.pathname, isOffer]);

	if (isScreenDisabled(currentQuiz, location.pathname)) {
		return null;
	}

	if (!isMain) {
		return (
			<nav className={styles.nav_wrap}>
				<div className={styles.bg}>
					<div className={styles.container}>
						{!isPremium ? (
							<a href="/" onClick={onClick} className={styles.backBtn}>
								<img
									src="/back.svg"
									width={24}
									height={24}
									alt="back icon"
								/>
							</a>
						) : null}

						<Link to="/">
							<img
								src="/logo.svg"
								width={width}
								height={height}
								alt="fabu logo"
							></img>
						</Link>
					</div>
					<ProgressBar />
					{Timer}
				</div>
				<div className={styles.bottom_shadow} />
			</nav>
		);
	}
}
