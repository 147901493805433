import styles from "./progress_bar.module.css";
import { useLocation, useParams } from "@remix-run/react";
import { useMemo } from "react";
import { getQuestionById, getQuizIdentifierFromPath } from "~/data";
import { quiz_questions_1 } from "~/data/quiz_questions";

export default function ProgressBar() {
	const { pathname } = useLocation();
	const { questionId } = useParams();
	const { currentQuiz } = getQuizIdentifierFromPath();
	const total = quiz_questions_1.length - 1;

	const currentQuestionId = useMemo(
		() => parseInt(questionId ?? "", 10),
		[questionId]
	);

	const index = quiz_questions_1.findIndex(
		(item) => item?.id === currentQuestionId
	);

	const adjustedIndex = index !== -1 ? (index > 0 ? index : 0) : 0;
	const percent = (adjustedIndex * 100) / total;
	const question = getQuestionById(parseInt(questionId ?? ""), currentQuiz);

	if (!pathname.includes("question") || question?.hideProgress) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.progress} style={{ width: `${percent}%` }} />
		</div>
	);
}
