import { useCallback, useState } from "react";
import FlexBox from "../questions/parts/flex/flex_box";
import { useNavigate } from "@remix-run/react";
import { ModalController } from "./entity";
import { SmallBtn } from "../questions/parts/button/small_button";
import storage, { StorageKeys } from "~/data/local_storage";

export default function WellbeingModal() {
	const navigate = useNavigate();
	const [isYesSelected, setIsYesSelected] = useState<boolean>(false);

	const sendForm = useCallback(() => {
		storage.set(StorageKeys.wellbeing, "1");
		ModalController.hideModal();
		return navigate("/generate", {
			replace: true,
		});
	}, [navigate]);

	const yes = useCallback(() => {
		setIsYesSelected(true);
		setTimeout(() => {
			sendForm();
		}, 300);
	}, [sendForm]);

	return (
		<>
			<h3 className="inter700 sp18 txt_center white">
				Are you ready to make a<br /> daily effort to improve your
				<br />
				mental wellbeing?
			</h3>
			<FlexBox
				style={{
					width: "auto",
					marginTop: 28,
				}}
			>
				<SmallBtn
					text="Yes"
					onClick={yes}
					style={{
						background: "var(--white-color)",
						color: "var(--black-color)",
						flex: 1,
						minHeight: 44,
						minWidth: 108,
						height: 44,
						borderRadius: 24,
						transform: `scale(${isYesSelected ? "1.1" : "1"})`,
					}}
				/>
			</FlexBox>
		</>
	);
}
