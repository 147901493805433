import PageTransition from "../page_transition";
import styles from "./index.module.css";

export default function NotFoundSection() {
	return (
		<PageTransition>
			<div className={styles.container}>
				<h1 className="inter700 white txt_center sp36 mb_28">
					Page not found
				</h1>
				<h2 className="inter700 white txt_center sp24">404</h2>
			</div>
		</PageTransition>
	);
}
