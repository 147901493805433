import { useCallback } from "react";
import { Button } from "../questions/parts/button/button";
import { useNavigate } from "@remix-run/react";
import { ModalController } from "./entity";
import styles from "./modal.module.css";
import tracking from "~/data/tracking";
import storage, { StorageKeys } from "~/data/local_storage";

export default function EmailModal() {
	const navigate = useNavigate();

	const agree = useCallback(() => {
		sendForm("1");
	}, []);

	const noThanks = useCallback(() => {
		sendForm("0");
	}, []);

	const sendForm = (val: string) => {
		storage.set(StorageKeys.preference, val?.toString() ?? "");

		tracking.track("pop_up_send_product_updates", {
			pop_up: val == "1" ? true : false,
		});

		ModalController.hideModal();
		navigate("/generate");
	};

	return (
		<>
			<div className={styles.email_img_wrap}>
				<img src="/modal.png" alt="email icon" />
			</div>

			<h2 className="inter700 sp26 txt_center white">
				May we send you product updates?
			</h2>
			<p className={`inter400 sp14 txt_center white ${styles.text}`}>
				You can change your mind at any time by clicking the unsubscribe
				link in the footer of any email you receive from us. Our Privacy
				Policy is available at link.
			</p>
			<Button text="Yes, I agree." onPress={agree} />
			<Button
				text="No, thanks"
				onPress={noThanks}
				css={{
					backgroundColor: "transparent",
					color: "#fff",
					marginTop: 12,
				}}
			/>
		</>
	);
}
